* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  .sign-up-parent {
    display: flex;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
    background-color: rgb(216, 248, 237);
  }
  
  .sign-up-form-container {
    width: 30vw;
    height: 28vw;
    border-radius: 10px;
    background-color: white;
    position: relative;
  }
  
  .sign-up-form-container h1 {
    margin-top: 5vh;
    margin-left: 12vw;
  }
  
  .sign-up-form-container p {
    margin-top: 2vh;
    margin-left: 4vw;
  }
  
  .sign-up-form-container input {
    display: block;
    height: 6vh;
    width: 24vw;
    margin-left: 3vw;
  
    border-radius: 3px;
    border: 1px solid #e2e2e2;
    margin-top: 3vh;
  }
  
  .sign-up-form-container button {
    display: block;
    height: 6vh;
    width: 24vw;
    margin-left: 3vw;
    border-radius: 3px;
    margin-top: 3vh;
    cursor: pointer;
    background-color: #4c57b6;
    color: white;
  }