th#ppdid {
  width: 8rem;
  height: 25px;
  /* position: absolute; */
  padding-left: 10px;
  padding-right: 3rem;
  left: 24.82%;
  right: 71.62%;
  top: 25.65%;
  bottom: 72.04%;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  color: #284e91;
}

.right-side {
  width: calc(100% - 16rem);
  left: 320px;
  background: #f5f5f5;
  top: 0px;
  border-radius: 0px;
}

.rectangle {
  height: 3px;
  width: calc(100% - 0rem);
  background: rgba(225, 249, 244, 1);
  border-radius: 0px;

  background: #e1f9f4;
}

.datatable-container {
  width: calc(100% - 2rem);
  margin-left: 2rem;
  background: #ffffff;
  border-radius: 0px;
}

.toggle-t {
  margin-top: 40px;
}
thead {
  /* height: 65px; */
  /* width: 1201px; */
  /* left: 361px; */
  background: rgba(255, 255, 255, 1);
  top: 255px;
  /* border-radius: 44px; */
}
V th#image {
  padding-right: 2rem;
  /* top: 25.65%; */
  /* bottom: 72.04%; */
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  color: #284e91;
}
th#image {
  padding-right: 2rem;
  /* top: 25.65%; */
  /* bottom: 72.04%; */
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  color: #284e91;
}

th#property {
  /* position: absolute; */
  /* left: 40.82%; */
  /* right: 54.62%; */
  padding-right: 3rem;
  top: 25.65%;
  bottom: 72.04%;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  color: #284e91;
}
th#contact {
  /* position: absolute; */
  /* left: 48.53%; */
  /* right: 47.35%; */
  padding-right: 4rem;
  /* top: 25.65%; */
  /* bottom: 72.04%; */
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  color: #284e91;
}
.ppdid {
  padding-left: 1rem;
}
th#area {
  padding-right: 4rem;
  /* position: absolute; */
  /* left: 60.17%;
    right: 37.46%;
    top: 25.65%;
    bottom: 72.04%; */
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  color: #284e91;
}

th#views {
  padding-right: 3rem;
  /* position: absolute; */
  /* left: 68.26%; */
  /* right: 28.74%; */
  /* top: 25.65%; */
  /* bottom: 72.04%; */
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  color: #284e91;
}
tr {
  border-radius: 1rem;
  border: 1px solid red;
}


th#status {
  padding-right: 4rem;
  /* position: absolute; */
  /* left: 74.81%; */
  /* right: 21.88%; */
  /* top: 25.65%; */
  /* bottom: 72.04%; */
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  color: #284e91;
}

th#days {
  padding-right: 3rem;
  /* position: absolute; */
  /* left: 83.87%; */
  /* right: 11.38%; */
  /* top: 25.65%; */
  /* bottom: 72.04%; */
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  color: #284e91;
}
th#action {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  width: 5rem;
  line-height: 25px;
  color: #284e91;
  cursor: pointer;
}
.ppd {
  margin-top: 5px;
}
.action {
  cursor: pointer;
}
