* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.basic {
  display: flex;
}

.form {
  display: flex;
  margin: 2%;
  padding: 2%;
  margin-right: -25%;
  justify-content: space-between;
}

li {
  list-style-type: None;
}

a {
  text-decoration: none;
  color: rgb(53, 50, 50);
}

.navbar {
  padding: 1%;
  background-color: aliceblue;
  width: 80%;
  margin-left: 7%;
}
.input {
  width: 50vh;
  height: 5vh;
}
.main {
  display: flex;
  flex-direction: row;
  margin-right: 10%;
  width: 100%;
}
.form-container {
  margin: 10%;
  margin-left: 10%;
  padding: 1%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 70%;
}

.form-container1 {
  margin: 5%;

  padding: 1%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 30%;
}

h4 {
  margin: 3%;
  padding: 1%;
}
.p-data {
  margin-left: 10%;
  margin-bottom: -7%;
  margin-top: 8%;
}

.button {
  margin-top: -10%;
  margin-left: 60%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1%;
}

.btn1 {
  padding: 2%;
  border-radius: 5px;

  width: 15vh;
  background-color: skyblue;
  cursor: pointer;
}

.btn2 {
  padding: 2%;
  border-radius: 5px;
  background-color: rgb(66, 66, 238);
  cursor: pointer;

  width: 15vh;
}

.rectangle {
  height: 3px;
  width: calc(100% - 0rem);
  background: rgba(225, 249, 244, 1);
  border-radius: 0px;

  background: #e1f9f4;
}
